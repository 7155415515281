body {
    margin: 0;
    padding: 0;
  }
  
  .login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .login-form form {
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    border: none;
  }
  
  /* Rest of the CSS remains the same */
  
  /* .login-form {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
    } */
  
  .form-group {
    margin-bottom: 10px;
    padding: 7px;
  }
  
  .loginHeader {
    text-align: center;
    padding-bottom: 10px;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .login-form input[type="text"],
  .login-form input[type="password"] {
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .login-form a {
    text-decoration: none;
  }
  
  .login-form .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .forgotPassword {
    padding: 6px;
    opacity: 0.9;
    margin-left: 250px;
    padding-bottom: 13px;
  }
  
  .login-form button[type="submit"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #5f0d61;
    color: white;
    border: none;
    border-radius: 150px;
    cursor: pointer;
  }
  
  .login-form button[type="submit"]:hover {
    background-color: #b22fca;
  }
  