.Sideabr{
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 4rem;
    transition: all 300ms ease;
    background-color: chartreuse;
}

.logo{
    display: flex;
    font-weight: bold;
    font-size: 22px;
    /* gap: 1rem; */
    align-items: center;
    justify-content: center;
    height:50px;
    margin-top: 10px;
    background-color: lightcoral;
   
    
}

.logo>img{
    width: 10rem;
    height: 5rem;
    position: absolute;
    left: 20px;
}


/*menu*/
.menu{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.menuItem{
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 2.5rem;
    margin-left: 2rem;
    position: relative;
    transition: all 300ms ease;
    font-size: 16px;
    border-radius: 0.7rem;
    width: 15%;
    font-weight: 500;

}

.menuItem:hover{
    cursor: pointer;
}

.active{
    background: rgb(172, 168, 168);
    margin-left: 0;
    font-weight: 700;
}

.active::before{
    content: '';
    width: 8px;
    height: 100%;
    background: grey;
    margin-right: calc(1rem - 8px);
}
.menu .menuItem:last-child{
    position: absolute;
    bottom: 2.3rem;
    width: 100%;
}
.menudrop{
    /* background-color: lightgreen; */
    display: flex;
    flex-direction: row;
    height: 720px;
    /* margin-top: 15px; */
}

.evotectlogo{
    margin-left: 25px;
    width: 169px;
    object-fit: cover;
    background-image: url('../../Assets/Evotech.png');
}

.headertitle{
    display: flex;
    flex-direction: row; 
    height: 100px; 
    background-color: whitesmoke;
}

.dashboardtitle{
    background-image: linear-gradient(to right, #F5F5F5 0%, #1F6357 100%);
   font-size: 14px;
   height: 100px;
   width: 1325px;
   font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
   text-align: center;
   border-radius: 3px;
   color: black;
}
.tex{
    width: 60px;
}

.left{
    background-color: whitesmoke;
    width: 280px;
}
.right{
    width: 1300px;
    background-image: linear-gradient(to right, #F5F5F5 0%, #227442 100%);
    /* background-image: url(../Picsdashboard/techtoolssstock.jpg); */
    box-shadow: 2px 2px silver;
    border-radius: 10px;

}


.onetex{
    height: 50px;
    width: 205px;
    padding-left: 4px;
    padding-top: 5px;
}

.btnone{
    height: 40px;
    width: 200px;
    border-radius: 16px;
    border: none;
    background-color: #00A36C;
    box-shadow: 2px 2px silver;
    color: white;
}
.btnone:hover{
    color: aliceblue;
transform: translateX(10px);
}


.hllspace{
    height: 70px;
}

.header{
    margin-left: 260px;
    background-color: none;
    color: #368067;
    /* align-items: center; */
    justify-content: center;
}