.lms{
    position: relative;
    background-image: url(../../Assets/home2.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
}

.btn-area{
    position: absolute;
    top: 540px;
    left: 150px;
    display: flex;
    background-color: none;
}

.btn-1{
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
    border: none;
   border-radius: 5px;
    color: #00bf63;
    background-color:white;
    z-index: 1;
}

.btn-1:hover{
    transform: translateY(-3px);
  }

.btn-2{
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    color: white;
    background-color:#00bf63;
    z-index: 1;
    margin-left: 20px;
}
.btn-2:hover{
    transform: translateY(-3px);
  }